import React from 'react';

const PaymentSuccess = () => {
  return (
    <div className="flex justify-center">
      <div className="flex my-5 border bg-green-300 p-5 rounded items-center w-[90%]">
        <div>Payment successfully done</div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
